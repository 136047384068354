import crossFetch from 'cross-fetch'

type Params = Record<string, unknown>

type DefaultParams = Pick<Required<RequestInit>, 'credentials' | 'headers'>
type Dependencies = { fetch: typeof crossFetch }

const defaultDependencies: Dependencies = { fetch: crossFetch }

export const jsonHeaders: HeadersInit = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export class InternalApiError extends Error {
  response: Response
  body?: Params

  constructor(response: Response, body?: Params) {
    super((body?.['error'] as string) ?? 'Something went wrong')
    this.response = response
    this.body = body
  }
}

export class InternalApiClient {
  constructor(
    private baseUrl: string,
    private csrfToken: string,
    private dependencies: Dependencies = defaultDependencies
  ) {}

  private get fetch() {
    return this.dependencies.fetch
  }

  async get<Response = unknown>(url: string, params: Params = {}) {
    const urlWithParams = this.mergeQuery(url, params)
    const res = await this.fetch(urlWithParams, {
      ...this.defaultParams(),
      method: 'GET',
    })

    const body = await res.json()
    if (!res.ok) {
      throw new InternalApiError(res, body)
    }
    return body as Response
  }

  async post<Response = unknown>(url: string, data: Params) {
    return this.request<Response>(url, data, 'POST')
  }

  async put<Response = unknown>(url: string, data: Params) {
    return this.request<Response>(url, data, 'PUT')
  }

  async patch<Response = unknown>(url: string, data: Params) {
    return this.request<Response>(url, data, 'PATCH')
  }

  async request<Response = unknown>(
    url: string,
    data: Params,
    method: 'PUT' | 'PATCH' | 'POST'
  ): Promise<Response> {
    const res = await this.fetch(this.fullUrl(url), {
      ...this.defaultParams(),
      method,
      body: JSON.stringify(data),
    })
    let body: Params | undefined
    try {
      body = await res.json()
    } catch {
      // continue regardless of error
    }
    if (!res.ok) {
      throw new InternalApiError(res, body)
    }
    return body as Response
  }

  async delete(url: string, params: Params = {}) {
    const urlWithParams = this.mergeQuery(url, params)
    const res = await this.fetch(urlWithParams, {
      ...this.defaultParams(),
      method: 'DELETE',
    })

    if (!res.ok) {
      throw new InternalApiError(res)
    }
  }

  private fullUrl(url: string) {
    return new URL(url, this.baseUrl).toString()
  }

  private defaultParams(): DefaultParams {
    const backupCsrf = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content')

    return {
      credentials: 'include',
      headers: {
        ...jsonHeaders,
        ['X-CSRF-Token' as keyof HeadersInit]: this.csrfToken || backupCsrf,
      },
    } as DefaultParams
  }

  private mergeQuery(url: string, params: Params) {
    const fullUrl = new URL(url, this.baseUrl)
    for (const [key, value] of Object.entries(params)) {
      fullUrl.searchParams.set(key, `${value}`)
    }

    return fullUrl.toString()
  }
}
